@import "/src/assets/theme/variable.scss";

/* font family */
@font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/ProductSans-Thin.eot");
  src: url("./assets/fonts/ProductSans-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ProductSans-Thin.woff2") format("woff2"),
    url("./assets/fonts/ProductSans-Thin.woff") format("woff"),
    url("./assets/fonts/ProductSans-Thin.ttf") format("truetype"),
    url("./assets/fonts/ProductSans-Thin.svg#ProductSans-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/ProductSans-ThinItalic.eot");
  src: url("./assets/fonts/ProductSans-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ProductSans-ThinItalic.woff2") format("woff2"),
    url("./assets/fonts/ProductSans-ThinItalic.woff") format("woff"),
    url("./assets/fonts/ProductSans-ThinItalic.ttf") format("truetype"),
    url("./assets/fonts/ProductSans-ThinItalic.svg#ProductSans-ThinItalic")
      format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/ProductSans-Light.eot");
  src: url("./assets/fonts/ProductSans-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ProductSans-Light.woff2") format("woff2"),
    url("./assets/fonts/ProductSans-Light.woff") format("woff"),
    url("./assets/fonts/ProductSans-Light.ttf") format("truetype"),
    url("./assets/fonts/ProductSans-Light.svg#ProductSans-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/ProductSans-LightItalic.eot");
  src: url("./assets/fonts/ProductSans-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ProductSans-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/ProductSans-LightItalic.woff") format("woff"),
    url("./assets/fonts/ProductSans-LightItalic.ttf") format("truetype"),
    url("./assets/fonts/ProductSans-LightItalic.svg#ProductSans-LightItalic")
      format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/ProductSans-Regular.eot");
  src: url("./assets/fonts/ProductSans-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ProductSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/ProductSans-Regular.woff") format("woff"),
    url("./assets/fonts/ProductSans-Regular.ttf") format("truetype"),
    url("./assets/fonts/ProductSans-Regular.svg#ProductSans-Regular")
      format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/ProductSans-Italic.eot");
  src: url("./assets/fonts/ProductSans-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ProductSans-Italic.woff2") format("woff2"),
    url("./assets/fonts/ProductSans-Italic.woff") format("woff"),
    url("./assets/fonts/ProductSans-Italic.ttf") format("truetype"),
    url("./assets/fonts/ProductSans-Italic.svg#ProductSans-Italic")
      format("svg");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/ProductSans-Medium.eot");
  src: url("./assets/fonts/ProductSans-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ProductSans-Medium.woff2") format("woff2"),
    url("./assets/fonts/ProductSans-Medium.woff") format("woff"),
    url("./assets/fonts/ProductSans-Medium.ttf") format("truetype"),
    url("./assets/fonts/ProductSans-Medium.svg#ProductSans-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/ProductSans-MediumItalic.eot");
  src: url("./assets/fonts/ProductSans-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ProductSans-MediumItalic.woff2") format("woff2"),
    url("./assets/fonts/ProductSans-MediumItalic.woff") format("woff"),
    url("./assets/fonts/ProductSans-MediumItalic.ttf") format("truetype"),
    url("./assets/fonts/ProductSans-MediumItalic.svg#ProductSans-MediumItalic")
      format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/ProductSans-Bold.eot");
  src: url("./assets/fonts/ProductSans-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ProductSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/ProductSans-Bold.woff") format("woff"),
    url("./assets/fonts/ProductSans-Bold.ttf") format("truetype"),
    url("./assets/fonts/ProductSans-Bold.svg#ProductSans-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/ProductSans-BoldItalic.eot");
  src: url("./assets/fonts/ProductSans-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ProductSans-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/ProductSans-BoldItalic.woff") format("woff"),
    url("./assets/fonts/ProductSans-BoldItalic.ttf") format("truetype"),
    url("./assets/fonts/ProductSans-BoldItalic.svg#ProductSans-BoldItalic")
      format("svg");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/ProductSans-BlackItalic.eot");
  src: url("./assets/fonts/ProductSans-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ProductSans-BlackItalic.woff2") format("woff2"),
    url("./assets/fonts/ProductSans-BlackItalic.woff") format("woff"),
    url("./assets/fonts/ProductSans-BlackItalic.ttf") format("truetype"),
    url("./assets/fonts/ProductSans-BlackItalic.svg#ProductSans-BlackItalic")
      format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/ProductSans-Black.eot");
  src: url("./assets/fonts/ProductSans-Black.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/ProductSans-Black.woff2") format("woff2"),
    url("./assets/fonts/ProductSans-Black.woff") format("woff"),
    url("./assets/fonts/ProductSans-Black.ttf") format("truetype"),
    url("./assets/fonts/ProductSans-Black.svg#ProductSans-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* font family end */

/* reset css */
* {
  padding: 0;
  margin: 0;
  outline: none !important;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-size: 16px;
  color: $c_dark;
  line-height: 1.5;
  *:not(i) {
    font-family: $product_sans !important;
  }
}
.pt_80,
.al_sc {
  padding-top: 80px;
  @media (max-width: 1200px) {
    padding-top: 50px;
  }
  @media (max-width: 767px) {
    padding-top: 40px;
  }
}
.pb_80,
.al_sc {
  padding-bottom: 80px;

  @media (max-width: 1200px) {
    padding-bottom: 50px;
  }
  @media (max-width: 767px) {
    padding-bottom: 40px;
  }
}
.text_center {
  text-align: center;
}

.d_flex {
  @include d_flx();
}
section[id] {
  scroll-margin-top: 60px;
}
.fs {
  &_56 {
    :where(h1, h2, h3, h4, h5, h6) {
      font-size: 56px;
      line-height: 1.25;
      @media (max-width: 1200px) {
        font-size: 42px;
      }

      @media (max-width: 991px) {
        font-size: 36px;
      }
      @media (max-width: 767px) {
        font-size: 30px;
      }
      @media (max-width: 575px) {
        font-size: 24px;
      }
    }
  }
  &_48 {
    :where(h1, h2, h3, h4, h5, h6) {
      font-size: 48px;
      line-height: 1.25;
      @media (max-width: 1200px) {
        font-size: 36px;
      }
      @media (max-width: 991px) {
        font-size: 28px;
      }
      @media (max-width: 575px) {
        font-size: 22px;
      }
    }
  }

  &_36 {
    :where(h1, h2, h3, h4, h5, h6) {
      font-size: 36px;
      line-height: 1.25;
      @media (max-width: 1200px) {
        font-size: 28px;
      }
      @media (max-width: 575px) {
        font-size: 22px;
      }
    }
  }
  &_24 {
    :where(h1, h2, h3, h4, h5, h6) {
      font-size: 24px;
      line-height: 1.25;
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
  }
}

.MuiButton-root {
  &.btn {
    font-size: 22px;
    border-radius: 50px;
    min-height: 65px;
    min-width: 185px;
    padding: 5px 15px;
    letter-spacing: 0;
    text-transform: capitalize;
    font-weight: 700;
    line-height: 1.3;
    transition: $transition;
    @media (max-width: 1200px) {
      font-size: 18px;

      min-height: 55px;
      min-width: 170px;
    }
    @media (max-width: 991px) {
      font-size: 16px;

      min-height: 45px;
      min-width: 150px;
    }
    &.sm_btn {
      min-height: 40px;
      min-width: 120px;
      padding: 2px 10px;
      font-size: 18px;
      text-transform: uppercase;
    }
    &.btn_primary {
      background: $primary;
      color: $btn_txt;
      border: 1px solid $primary;
      &:hover {
        background: $c_dark;
        color: $white;
      }
    }

    &.btn_black {
      background: $c_dark;
      border: 1px solid $c_dark;
      color: $white;
      &:hover {
        background: transparent;
        color: $c_dark;
      }
    }
  }
}
p {
  font-size: 20px;
  @media (max-width: 991px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
    line-height: 1.3;
  }
}

.container {
  max-width: 1170px;
  width: 90%;
  margin: 0 auto;
}

li {
  list-style: none;
}

img {
  max-width: 100%;
}
a {
  text-decoration: none;
}
.wrapCls {
  min-height: calc(100vh - 557px);
  padding-bottom: 60px;
  @media (max-width: 767px) {
    padding-bottom: 40px;
  }
}

/* about us section */
.about_us_sc {
  .sec_head {
    max-width: 850px;
    margin: 0 auto 30px;
  }
  p {
    margin-top: 30px;
    @media (max-width: 1200px) {
      margin-top: 16px;
    }
  }
  .abt_img {
    max-width: 1100px;
    width: 100%;
    margin-inline: auto;
    @media (max-width: 1200px) {
      max-width: 700px;
    }
    @media (max-width: 991px) {
      max-width: 600px;
    }
  }
}
/* about us section end */

/* caluclate loan */

.calculate_loan_sc {
  background: $primary;
  .mn_calc_loan {
    @include d_flx(center, flex-start, 126px);
    @media (max-width: 991px) {
      @include d_flx(center, flex-start, 40px);
    }
    @media (max-width: 767px) {
      @include d_flx(center, flex-start, 24px);
    }
    .calc_lft,
    .calc_ryt {
      width: calc(50% - (126px / 2));
      @media (max-width: 991px) {
        width: calc(50% - (40px / 2));
      }
      @media (max-width: 767px) {
        width: 100%;
        text-align: center;
      }
    }

    .calc_lft {
      @media (max-width: 767px) {
        figure {
          max-width: 350px;
          margin-inline: auto;
        }
      }
    }
    p {
      margin: 20px 0 50px;
      @media (max-width: 1200px) {
        margin: 12px 0 30px;
      }
      @media (max-width: 575px) {
        margin: 10px 0 20px;
      }
    }
  }
}

/* test sec */

//testimonials
.testimonial_sc {
  .sec_head {
    margin-bottom: 50px;
    @media (max-width: 1200px) {
      margin-bottom: 30px;
    }
  }
  .test_img {
    max-width: 405px;
    margin: 0 auto 50px;
    @media (max-width: 1200px) {
      margin: 0 auto 30px;
    }
    @media (max-width: 767px) {
      max-width: 300px;
    }

    @media (max-width: 575px) {
      max-width: 250px;
    }
    .slick-slide {
      padding: 20px 0;
      figure {
        width: 135px;
        height: 135px;
        overflow: hidden;
        border-radius: 135px;
        border: 3px solid transparent;
        @media (max-width: 767px) {
          width: 100px;
          height: 100px;
        }
        @media (max-width: 575px) {
          width: 80px;
          height: 80px;
        }
        img {
          @include img_contain(cover);
        }
      }
      &.slick-center {
        figure {
          transform: scale(1.3);
          border: 3px solid $primary;
          z-index: 9;
          position: relative;
          overflow: hidden;
          background: $white;
        }
      }
    }
  }
  .test_cnt {
    max-width: 926px;
    margin-inline: auto;
    .info_top {
      background: $light_gray;
      border-radius: 10px 10px 0 0;
      padding: 50px 70px;
      @media (max-width: 1200px) {
        padding: 30px 40px;
      }
      @media (max-width: 767px) {
        padding: 30px 20px;
      }
      @media (max-width: 575px) {
        padding: 30px 15px;
      }
      p {
        margin-top: 16px;
        color: $gray;
      }
    }
    .info_btm {
      padding: 20px;
      background: $c_dark;
      border-radius: 0 0 20px 20px;
      @media (max-width: 767px) {
        padding: 10px;
      }
      h6 {
        color: $white;
      }
    }
  }
  .slick-dots {
    bottom: -60px;
    @media (max-width: 767px) {
      bottom: -35px;
    }
    li {
      margin: 0;
      button {
        padding: 0;
        &::before {
          font-size: 15px;
          color: $gray;
          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
      &.slick-active {
        button {
          &::before {
            color: $c_dark;
          }
        }
      }
    }
  }
}

/* test sec end */

/* faq start  */
.mn_faqs {
  .sec_head {
    margin-bottom: 60px;
    @media (max-width: 1200px) {
      margin-bottom: 40px;
    }
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
  }
  .MuiAccordion-root.MuiAccordion-rounded {
    border-radius: 17px;
    border: 1px solid $primary;
    box-shadow: 12px 22px 47px #b1aeae1a;
    &:not(:last-child) {
      margin-bottom: 48px;
      @media (max-width: 1200px) {
        margin-bottom: 30px;
      }
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
  }
  .MuiAccordionSummary-root {
    min-height: 100px;
    .MuiAccordionSummary-expandIconWrapper {
      width: 32px;
      height: 32px;
      @media (max-width: 991px) {
        width: 20px;
        height: 20px;
      }
    }
    @media (max-width: 1200px) {
      min-height: 80px;
    }
    @media (max-width: 991px) {
      min-height: 70px;
    }
    @media (max-width: 767px) {
      min-height: 50px;
    }
    p {
      font-size: 29px;
      color: $btn_txt;
      font-weight: 900;
      @media (max-width: 1200px) {
        font-size: 24px;
      }
      @media (max-width: 991px) {
        font-size: 20px;
      }
      @media (max-width: 767px) {
        font-size: 18px;
      }
      @media (max-width: 575px) {
        font-size: 16px;
      }
    }
  }
  .MuiAccordionDetails-root {
    p {
      color: $gray;
    }
  }
  .MuiAccordion-root {
    &::before {
      display: none;
    }
  }
}
@media (max-width: 767px) {
  .humb_btn {
    display: inline-flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 0;
    @media (max-width: 575px) {
      width: 35px;
      height: 35px;
    }
  }
}
@media (min-width: 768px) {
  .humb_btn {
    display: none;
  }
}

.Pagination {
  .MuiPaginationItem-page {
    // Your styles here
    background-color: #a0dfdf !important;
    // Add more styles as needed
  }
}

/* faq end */
.crs_mn {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 100px 0;
}
.left {
  background: #f3f3f3;
  width: 40%;
  padding: 20px;
}
.left h2 {
  color: #201637;
  font-size: 37px;
  margin-top: 100px;
}
.left h3 {
  color: #272c3f;
  font-size: 37px;
  font-weight: 400;
}
.crs_lft figure {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  background: #fff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.crs_lft img{
  width: 100%;
  height: 100%;
}
.crs_ryt_mn {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 30px;
}
.crs_ryt {
  width: 48%;
  background: #f7f7f7;
  border: 1px solid #a0dfdf;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.crs_ryt img
{
  height: 100%;
  width: 100%;

}
.right .css-19kzrtu {
  padding: 0 !important;
}
.tabs .MuiButtonBase-root {
  color: #262626;
  width: 200px;
}
.right {
  width: 50%;
}
.tabs .css-1aquho2-MuiTabs-indicator {
  background-color: unset !important;
}
.tabs {
  background: #f7f7f7;
  border-radius: 14px;
  width: fit-content;
  padding: 5px;
}
.crs_ryt h3 {
  font-size: 12px;
  color: #201637;
}
.crs_ryt h4 {
  font-size: 12px;
  color: #272c3f;
}
.tabs .Mui-selected {
  background: #a0dfdf 0% 0% no-repeat padding-box;
  border-radius: 14px;
}
.tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #201637;
  font-weight: 800;
  font-size: 14px;
  min-height: 40px;
  width: 200px;
}
.crsryt_bx figure {
  width: 160px;
  height: 90px;
  padding: 70px 0px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.crsryt_bx {
  background-color: #f3f3f3;
  width: 28%;
}
.crsryt_img {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 35px;
  flex-wrap: wrap;
  margin-top: 30px;
}
.crsryt_cntnt {
  background: #ffffff;
  padding: 10px;
  border: 1px solid #f3f3f3;
}
.crsryt_bx.active .crsryt_cntnt {
  background: #a0dfdf;
}
.crsryt_cntnt h3 {
  font-size: 15px;
}
.crsryt_cntnt h4 {
  font-size: 12px;
  font-weight: 400;
  color: #272c3f;
}

.clcultelon_mn {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
}
.clcultelon_sc {
  padding: 65px 0 87px;
}
.clcultelon_sc h1 {
  font-size: 24px;
  text-align: center;
}
.clcultelon_lst {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 48px #0000000a;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin-top: 22px;
}
.clcultelon_lst h3 {
  color: #201637;
  font-size: 14px;
  font-weight: 800;
}
.clcultelon_lst > p {
  font-size: 13px;
  color: #736e7c;
  font-weight: 500;
  margin: 5px 0;
}

.clcultelon_lst h4 {
  font-size: 22px;
  color: #2d3131;
  font-weight: 900;
}
.clcultelon_lst .css-1hskriy {
  width: 250px;
  margin: 0 auto;
}
.clcultelon_lst .MuiSlider-root {
  color: #a0dfdf;
}
.emi_bx {
  background: #ddf4f4;
  box-shadow: 0px 13px 66px rgb(0 0 0 / 15%);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  margin-top: 50px;
}
.emi_inr {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px;
  border-radius: 20px;
  background: #e4f6f6;
  width: 195px;
  height: 137px;
}
.emi_inr h2 {
  font-size: 100px;
  color: #000;
}
.clcultelon_ryt,
.clcultelon_lft,
.clcultelon_cntr {
  width: 30%;
}
.emi_bx P {
  color: #1d4a79;
  font-size: 10px;
  margin-top: 10px;
}
.emi_bx h3 {
  font-size: 26px;
}
.slct .css-yf8vq0-MuiSelect-nativeInput {
  border: unset;
  opacity: unset;
  width: unset;
  box-sizing: unset;
  top: 0;
  background: unset;
  font-size: 0;
}
.slct .MuiSelect-select {
  padding-right: 0 !important;
}
.clcultelon_cntr .MuiFormControl-root {
  width: 100%;
  background: #fff;
  padding: 20px;
  margin: 0;
  border-radius: 8px;
}
.clcultelon_lft h3 {
  font-size: 30px;
}
.clcultelon_lft h4 {
  color: #c24437;
  margin-top: 26px;
}
.crs_ryt figure {
  width: 54px;
  height: 54px;
  background: #fff;
  border-radius: 15px;
  padding: 10px;
}
.crs_lt figure {
  width: 100%;
  height: 280px;
  background: unset;
}
.crs_lt h2 {
  font-size: 40px;
  font-weight: 700;
}
.clcultelon_lft svg path {
  stroke: #a0dfdf !important;
  stroke-width: 10 !important;
}
.App {
  position: relative;
}
.clcultelon_inr {
  top: 163px;
  position: absolute;
  left: 134px;
  bottom: 0;
}
.clcultelon_lft svg {
  width: 340px;
  height: 340px;
}
.clcultelon_lft svg circle {
  stroke-width: 10 !important;
}
.clcultelon_cntr
  .css-n70jm4-MuiInputBase-root-MuiInput-root-MuiSelect-root::before {
  border-bottom: 1px solid #ddd;
}
.clcultelon_cntr .MuiFormHelperText-root {
  color: #000;
  font-size: 14px;
  font-weight: 700;
}
.clcultelon_cntr
  .css-n70jm4-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover:not(
    .Mui-disabled,
    .Mui-error
  ):before {
  border-bottom: 1px solid #ddd;
}

.clcultelon_ryt .clcultelon_lst {
  margin-top: 0;
}
.left .css-19kzrtu {
  padding: 0;
}
@media (max-width: 1200px) {
  .left {
    width: 80%;
    text-align: center;
    margin: auto;
  }
  .left h2 {
    font-size: 30px;
    margin-top: 30px;
  }
  .left h3 {
    font-size: 24px;
  }
  .right {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .clcultelon_lft {
    width: 100%;
    text-align: center;
  }
  .clcultelon_cntr,
  .clcultelon_ryt {
    width: 48%;
  }
  .emi_bx {
    margin-top: 30px;
  }
}
@media (max-width: 992px) {
  .crs_mn {
    padding: 100px 0 40px;
    position: relative;
  }
  .right .css-1ujnqem-MuiTabs-root {
    position: absolute;
    top: 30px;
    right: 0;
    left: 0;
    margin: auto;
  }
  .crs_lft figure {
    width: 100px;
    height: 100px;
  }
  .crs_lft img {
    max-width: 58%;
  }
  .crs_lft h2 {
    margin-top: 0px !important;
  }
  .crs_lt figure {
    width: 100%;
    height: 100%;
  }
  .crs_lt img {
    max-width: 60%;
  }
  .left h3 {
    font-size: 18px;
  }
  .clcultelon_sc {
    padding: 40px 0;
  }
  .emi_inr {
    width: 130px;
    height: 130px;
  }
  .emi_inr h2 {
    font-size: 70px;
  }
}
@media (max-width: 600px) {
  .crs_ryt,
  .clcultelon_cntr,
  .clcultelon_ryt,
  .left {
    width: 100%;
  }
  .crsryt_bx {
    width: 48%;
  }
  .left h2 {
    font-size: 24px;
    margin-top: 10px;
  }
  .crsryt_img {
    gap: 15px;
    justify-content: space-between;
  }
  .clcultelon_ryt,
  .emi_bx,
  .clcultelon_lst,
  .clcultelon_mn {
    margin-top: 15px;
  }
  .emi_inr h2 {
    font-size: 60px;
  }
  .emi_bx h3 {
    font-size: 20px;
  }
  .clcultelon_lst h4 {
    font-size: 18px;
  }
}
@media (max-width: 480px) {
  .left h2 {
    font-size: 18px;
    margin-top: 18px;
  }
  .crsryt_bx {
    width: 100%;
    text-align: center;
  }
  .right .css-1ujnqem-MuiTabs-root {
    white-space: nowrap;
    display: flex !important;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .tabs .MuiButtonBase-root,
  .tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    width: 160px;
    font-size: 13px;
  }
  .crs_ryt_mn {
    gap: 10px;
    margin-top: 15px;
  }
  .crsryt_img {
    margin-top: 10px;
  }
  .crs_lt img {
    max-width: 100%;
  }
}
