@import "../../assets/theme/variable.scss";

.intro_sec {
  background: url("../../../public/static/images/home_bnr.png");
  background-repeat: no-repeat;
  min-height: calc(100vh - 100px);
  background-size:cover;
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width:767px){
    padding: 50px 0;
    min-height: auto;
    background-size: cover;
    background-position:  center;
  }
  .banner_head{
    max-width: 470px;
    @media (max-width:767px){
      margin: 0 auto;
      text-align: center;
    }
    h1{
      color: $white;
    }
    p{
      margin: 20px 0 50px;
      color: $white;
      @media (max-width:1200px){
        margin: 12px 0 30px;
      }
      @media (max-width:575px){
        margin: 10px 0 20px;

      }
    }
  }
}

//download app
.mn_bnr_dwnld{
  @include d_flx(center,flex-start,150px);
  @media (max-width:991px){
    @include d_flx(center,flex-start,40px);

  }
  .dwnld_bnr_lft,.dwnld_bnr_ryt{
    width: calc(50% - (150px / 2));
    @media (max-width:991px){
      width: calc(50% - (40px / 2));

    }
    @media (max-width:767px){
      width: 100%;
      text-align: center;
    }
  }
  .dwnld_bnr_lft{
    p{
      margin: 20px 0 50px;
      @media (max-width:1200px){
        margin: 12px 0 30px;
      }
    }
    .plystr_btns{
  @include d_flx(center,flex-start,18px);
  @media (max-width:767px){
    @include d_flx(center,center,18px);

  }
      figure{
        width: 150px;
        cursor: not-allowed;
        @media (max-width:767px){
          width: 130px;
        }
      }
    
    }
  
  }
  .dwnld_bnr_ryt{
    figure{
    @media (max-width:767px){
      max-width: 350px;
      margin-inline: auto;

    }
    }
  }
}
