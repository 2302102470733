@import "/src/assets/theme/variable.scss";

.hdr {
  background: $c_black;
  padding: 20px 0;
  position: sticky;
  top: 0;
  z-index: 9;
  transition: $transition;
  &.scrolled{
    padding: 8px 0;
    .hdr_mn {
      .hdr_lft {
        figure{
          height: 45px;
          width: 60px;
          @media (max-width:767px){
            height: 30px;
            width: 45px;
          }
        }
      }
    }
  }
  @media (max-width:767px){
    padding: 10px 0;
  }
  .hdr_mn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .hdr_lft {
      figure {
        height: 60px;
        width: 85px;
        display: inline-flex;
        transition: $transition;
        align-items: center;
        @media (max-width:767px){
          height: 45px;
          width: 65px;
        }
        img {
          object-fit: contain;
        }
      }
    }
    .hdr_ryt {
      @include d_flx(center,flex-start,30px);
      margin-left: auto;
      margin-right: 30px;
      @media (max-width:991px){
      @include d_flx(center,flex-start,18px);

      }
      @media (max-width:767px){
        position: absolute;
        inset: 100% 0 auto;
        background: $c_black;
        flex-direction: column;
        width: 100%;
        margin: 0;
        align-items: flex-start;
        padding: 20px 40px;
        opacity: 0;
        z-index: -9;
        transform: translateY(-100%);
      
        transition: transform opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        &.actv{
          opacity: 1;
          z-index: 99;
        transform: translateY(0);
     
      
        }
        + .btn{
          margin: 0 20px 0 auto;
          @media (max-width:575px){
            min-height: 35px;
            font-size: 16px;
            min-width: 90px;
          }
        }
      }
      li {
        a {
          color: $white;
          font-size: 20px;
          transition: $transition;
          @media (max-width:991px){
            font-size: 18px;
          }
          &:hover{
            color: $primary;
          }
          
        }
    
      }
    }
  }
}

//footer

.ftr {
  
    background: $c_black;
    .footer_flx{
      @include d_flx(stretch,space-between,10px);
      margin-bottom: 40px;
      @media (max-width:991px){
        row-gap: 30px;
      }
      @media (max-width:767px){
        margin-bottom: 30px;
      }
      .footer_cnt_bx{
        max-width: 242px;
        @media (max-width:991px){
          max-width: 100%;
        }
        &:first-child{
          @media (max-width:991px){
            width: 100%;

          }
        }
        &:last-child{
          max-width: 273px;
        }
        h4{
          font-size: 20px;
          color: $white;
          font-weight: 700;
          margin-bottom: 20px;
          @media (max-width:767px){
            margin-bottom: 12px;
            font-size: 16px;
          }
        }
        .mn_ftr{
          li{
            a{
              font-size: 16px;
              display: inline-flex;
              color: $white;
                cursor: pointer;
                transition: $transition;
                @media (max-width:767px){
                  font-size: 14px;
                }
                &:hover{
                  color: $primary;
                }
            }
            &:not(:last-child){
              margin-bottom: 20px;
              @media (max-width:767px){

                margin-bottom: 8px;
              }
            }
          }
        }
        .logo{
          width: 85px;
          height: 58px;
          @media (max-width:991px){
            margin-inline:auto ;
          }
          img{
            @include img_contain();
          }
           + p{
            font-size: 16px;
            color: $white;
            margin-top: 20px;
            @media (max-width:991px){
              margin-inline: auto;
              max-width: 450px;
            }
           }
        }
        .scl_icns {
       
          display: flex;
          justify-content: flex-start;
          figure {
            background: $white;
            height: 35px;
            width: 35px;
            margin-right: 12px;
            border-radius: 50%;
            padding: 11px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $transition;
            &:hover{
              background: $primary;
            }
          }
        }
        .subscribe_ftr {
        
          .ftr_txtfld {
            width: 260px;
            border-radius: 4px;
            margin-top: 16px;
            input {
              background-color: #292929;
              color: $white;
              border-radius: 4px 0 0 4px !important;
              padding: 13px;
              @media (max-width:767px){
                padding: 10px;
              }
              &::placeholder {
                color: white;
              }
            }
          }
        }
        .sub_btn {
          display: flex;
          justify-content: center;
          margin-bottom: 40px;
          @media (max-width:991px){
            margin-bottom: 24px;
          }
          @media (max-width:767px){
            margin-bottom: 16px;
          }
          & > div {
            width: 60% !important;
            margin-top: 0px !important;
            input {
              width: 100%;
            }
          }
          button {
            width: 40%;
            background: $primary;
            border: none;
            border-radius: 0 4px 4px 0;
            font-size: 15px;
            color: $btn_txt;
            cursor: pointer;
          }
        }
      }
    }
  
  }
  .ftr_btm {

    padding: 25px 0;
    border-top: 1px solid #a1a1a1;
    color: $white;
    text-align: center;
    @media (max-width:767px){
      padding: 15px 0;
    }
    p {
      font-size: 14px;
    
    }
  }

.select_div > div {
  // padding-right:  20px !important;
  border: none !important;
}
.select_div fieldset {
  display: none;
}
