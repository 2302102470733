$secondary: #820000;
$primary: #a0dfdf;
$lightGrey: #bebebe;
$white: #ffffff;
$gray: #6a6a6a;
$c_black: #1a1a1a;
$c_dark: #000;
$product_sans:'Product Sans';
$btn_txt:#201637;
$c_primary_hover: #51aed8;
$transition:all .3s ease-in-out 0s;
$light_gray:#fffaf1;
/* mixin  */

@mixin d_flx($align: center, $justy: flex-start, $gap: 0) {
    display: flex;
    align-items: $align;
    justify-content: $justy;
    flex-wrap: wrap;
    gap: $gap;
  }
  @mixin img_contain($object: contain) {
    width: 100%;
    height: 100%;
    object-fit: $object;
  }
  
